import React from 'react';
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../components/Typography';
import {
    Grid as MuiGrid,
    Link,
    Divider,
    Container,
    Box
} from "@material-ui/core";
import { NavLink as RouterNavLink } from "react-router-dom";
import { ContactRoutes, LegalRoutes } from "../../Routes";

const Grid = styled(MuiGrid)`
    && {
    padding: 10px
}
`;

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

function NavLinks({ classes, name, to, icon, component }) {
    return (
        <li className={classes.listItem}>
        <Link to={to} component={NavLink}>
            {name}
        </Link>
        </li>
    );
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://everywhensoftware.com/">
                Everywhen Software
            </Link>{' '}
            {new Date().getFullYear()}
            
        </Typography>
    );
}

function Version() {
    return (
        <Typography style={{ fontSize: '8px'}} color="textSecondary" align="right">
            {'1.0.12'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: '0px 0px 15px 0px',
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    margin: 0,
      width: 150,
      padding: 5,
    },
    copyright: {
        margin: 0,
        width: 150,
        padding: 5,
    }
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
    <Container className={classes.container}>
              <Grid container spacing={4} justify="space-evenly">
            <Grid item xs={12} sm={4} className={classes.icons}>
                <a href="https://facebook.com/EverywhenSoftware" className={classes.icon}>
                <img src="/static/img/appFooterFacebook.png" alt="Facebook" />
                </a>
                <a href="https://twitter.com/EverywhenSoftware" className={classes.icon}>
                <img src="/static/img/appFooterTwitter.png" alt="Twitter" /> 
                </a>  
                </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="h6" marked="left" gutterBottom>
                    Company
                </Typography>
                      <ul className={classes.list}>
                          {ContactRoutes.map((route, index) => (
                                  <NavLinks
                                      name={route.name}
                                      to={route.path}
                                      key={index}
                                      classes={classes}
                                  />
                          ))}
                </ul>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="h6" marked="left" gutterBottom>
                  Legal
                </Typography>
                <ul className={classes.list}>
                          {LegalRoutes.map((route, index) => (
                              <NavLinks
                                  name={route.name}
                                  to={route.path}
                                  key={index}
                                  classes={classes}
                              />
                          ))}
                </ul>
                  </Grid>
              </Grid>
              <Divider my={6} />
              <Box mt={3}>
                  <Copyright /> <Version />
              </Box>
     </Container>
    </Typography>
  );
}
