import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterNavLink } from "react-router-dom";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Campaigns() {
  const classes = useStyles();
  return (
    <React.Fragment>
       <Typography component="h2" variant="h6" color="primary" gutterBottom>
      Recent Campaigns
    </Typography>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        on 15 June, 2020
      </Typography>
      <div>
        <Link color="primary" to="/campaigns" component={NavLink}>
          View Campaigns
        </Link>
      </div>
    </React.Fragment>
  );
}
