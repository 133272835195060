import React from 'react';
import Helmet from 'react-helmet';
import {
    Grid,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Container,
    Typography,
    MenuItem
} from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '../../components/Snackbar';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function MyContactForm () {
    const classes = useStyles();

    class ContactForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                open: false,
                cMessage: "",
                firstName: "",
                lastName: "",
                emailAddress: "",
                subject: "Inquiry",
                message: "",
                firstNameError: false,
                lastNameError: false,
                emailAddressError: false,
                subjectError: false,
                messageError: false
            };
            this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
            this.handleLastNameChange = this.handleLastNameChange.bind(this);
            this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
            this.handleSubjectChange = this.handleSubjectChange.bind(this);
            this.handleMessageChange = this.handleMessageChange.bind(this);
        }
        
        handleFirstNameChange = (e) => {
            this.setState({ firstName: e.target.value, firstNameError: false });
        };
        
        handleLastNameChange = (e) => {
            this.setState({ lastName: e.target.value, lastNameError: false });
        };
        
        handleEmailAddressChange = (e) => {
            this.setState({ emailAddress: e.target.value, emailAddressError: false });
        };
        
        handleSubjectChange = (e) => {
            this.setState({ subject: e.target.value, subjectError: false });
        };
        
        handleMessageChange = (e) => {
            this.setState({ message: e.target.value, messageError: false });
        };

        handleClose = (e) => {
            this.setState({ cMessage: "", open: false });
        };
        
        SendEmail = (e) => {
            e.preventDefault();

            const { firstName, lastName, emailAddress, subject, message } = this.state;

            if (firstName === "") {
                this.setState({ firstNameError: true });
            }
            else if (lastName === "") {
                this.setState({ lastNameError: true });
            }
            else if (emailAddress === "") {
                this.setState({ emailAddressError: true });
            }
            else if (subject === "") {
                this.setState({ subjectError: true });
            }
            else if (message === "") {
                this.setState({ messageError: true });
            }
            else {
                const requestOptions = {
                    method: 'POST',
                    //mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'POoZVoFTkgaEmb4dkSgp78lEcGeF72IRaKH7Deaz'
                    },
                    body: JSON.stringify({ 
                        "emailaddress": emailAddress, 
                        "subject": subject, 
                        "message": message, 
                        "firstname": firstName, 
                        "lastname": lastName, 
                    })
                };
                fetch('https://0wn4tevge0.execute-api.us-west-2.amazonaws.com/sandbox/sendcontactemail', requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.err != null) {
                                this.setState({
                                    cMessage: "Unable to send email.",
                                    open: true
                                });
                            }
                            else {
                                this.setState({
                                    open: true,
                                    firstName: "",
                                    lastName: "",
                                    emailAddress: "",
                                    subject: "Inquiry",
                                    message: "",
                                    firstNameError: false,
                                    lastNameError: false,
                                    emailAddressError: false,
                                    subjectError: false,
                                    messageError: false,
                                    cMessage: "Thanks for your email!"
                                 });
                            }
                        },
                        (error) => {
                            this.setState({
                                cMessage: "Unable to send email.",
                                open: true
                            });
                        }
                    )
            }
        };
        
        render = () => {
            const { open, cMessage, subject, firstNameError, lastNameError, emailAddressError, subjectError, messageError } = this.state;

            return (
                <Container component="main" maxWidth="sm">
                    <Helmet title='Contact Us | Everywhen Software' />
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <MailOutline />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Contact Us
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="fname"
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                        error={firstNameError}
                                        onChange={this.handleFirstNameChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        error={lastNameError}
                                        onChange={this.handleLastNameChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        error={emailAddressError}
                                        onChange={this.handleEmailAddressChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="topic"
                                        select
                                        value={subject}
                                        label="Topic"
                                        helperText="How can we help?"
                                        name="topic"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        error={subjectError}
                                        onChange={this.handleSubjectChange}
                                    >
                                        <MenuItem key='Inquiry' value='Inquiry'>
                                            Inquiry
                                        </MenuItem>
                                        <MenuItem key='Sales' value='Sales'>
                                            Sales
                                        </MenuItem>
                                                <MenuItem key='Suggestion' value='Suggestion'>
                                                    Suggestion
                                        </MenuItem>
                                                <MenuItem key='Support' value='Support'>
                                                    Support
                                        </MenuItem>
                                                <MenuItem key='Other' value='Other'>
                                                    Other
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="message"
                                        label="Message"
                                        id="message"
                                        multiline
                                        rows={10}
                                        error={messageError}
                                        onChange={this.handleMessageChange}
                                    />
                                    </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={this.SendEmail}
                            >
                                Send
                            </Button>
                        </form>
                    </div>
                    <Snackbar
                        open={open}
                        onClose={this.handleClose}
                        message={cMessage}
                    />
                </Container>
            );
        }
    }

    return <ContactForm />
}

export default MyContactForm;