import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '../../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../../components/Toolbar';
import logo from '../../img/marketlistlogowhite.png';
import { Hidden, Link } from "@material-ui/core";
import NavigationMenu from './MenuNavigation';
import AccountMenu from './MenuAccount';
import { NavRoutes } from "../../Routes";
import { NavLink as RouterNavLink } from "react-router-dom";

const styles = (theme) => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
  },
  left: {
      flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
    },
    logo: {
        maxWidth: '50px',
        paddingTop: '10px'
    }
});

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

function NavLinks({ classes, name, to }) {
    return (
        <Link
            color="inherit"
            variant="h6"
            underline="none"
            className={classes.rightLink}
            to={to}
            component={NavLink}
        >
            {name}
        </Link>
    );
}

function AppAppBar(props) {
    const { classes} = props;

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <Hidden mdUp>
                    <div className={classes.left} >
                            <NavigationMenu />
                    </div>
                        </Hidden>
                    <Link
                        color="inherit"
                        variant="h6"
                        underline="none"
                        className={classes.rightLink}
                        href="/"
                    >
                        <img src={logo} className={classes.logo} alt='Everywhen logo' />
                    </Link>
                    <Hidden smDown>
                        {NavRoutes.map((route, index) => (
                            index > 0 ?
                            (<NavLinks
                                name={route.name}
                                to={route.path}
                                key={route.name}
                                classes={classes}
                                />) :
                                (null)
                        ))}
                        </Hidden>
                    <div className={classes.right}>
                        <AccountMenu login={false} />
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.placeholder} />
        </div>
    );
}

AppAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
