import React from 'react';
import Helmet from 'react-helmet';
import { 
  makeStyles,
  Typography,
  Snackbar,
  IconButton
} from '@material-ui/core';

import {Close as CloseIcon} from '@material-ui/icons';

import AppForm from '../components/AppForm';
import { email as validateEmail } from '../../form/validation';
import RFTextField from '../../form/RFTextField';
import FormButton from '../../form/FormButton';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [sent, setSent] = React.useState(false);
  const [emailaddress, setEmail] = React.useState('');
  const [emailError, setError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SendEmail = (e) => {
    e.preventDefault();

  if (emailaddress === "") {
    setError(true);
  }
  else if (validateEmail(emailaddress)) {
    setError(true);
  }
  else {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'POoZVoFTkgaEmb4dkSgp78lEcGeF72IRaKH7Deaz'
        },
        body: JSON.stringify({ 
            "emailaddress": emailaddress
        })
    };
    fetch('https://0wn4tevge0.execute-api.us-west-2.amazonaws.com/sandbox/forgotpassword', requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.err) {
                  setSent(false);
                  setOpen(true);
                  setMessage("Unable to send email.");
                }
                else {
                    setSent(true);
                    setOpen(true);
                    setMessage("Password reset email sent.");
                }
            },
            (error) => {
              setSent(false);
              setOpen(true);
              setMessage("Unable to send email.");
            }
        )
    }
  };

  return (
    <React.Fragment>
      <Helmet title='Forgot Password | Everywhen Software' />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Forgot your password?
          </Typography>
          <Typography variant="body2" align="center">
            {"Enter your email address below and we'll " +
              'send you a link to reset your password.'}
          </Typography>
        </React.Fragment>
            <form onSubmit={SendEmail} className={classes.form} noValidate>
              <RFTextField
                autoFocus
                autoComplete="email"
                disabled={sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
                error={emailError}
                onChange={handleEmailChange}
              />
              <FormButton
                className={classes.button}
                disabled={sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {sent ? 'Email sent' : 'Send reset link'}
              </FormButton>
            </form>
      </AppForm>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={message}
        action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
    </React.Fragment>
  );
}

export default ForgotPassword;
