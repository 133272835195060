export var getUserInfo = function (cookie) {
    var myValue = '{"displayname": "", "apikey": "", "stage": ""}';
    var cookieArray;
    if (cookie) {
        cookieArray = cookie.split(";");
        cookieArray.map(function (originalValue) {
            originalValue = originalValue.trim();
            if (originalValue.indexOf("UserInfo") === 0){
                myValue = originalValue;
                myValue = myValue.replace("UserInfo=", "");
                return true;
            }
            return false;
        });
    }
    else {
        cookieArray = document.cookie.split(";");
        cookieArray.map(function (originalValue) {
            originalValue = decodeURIComponent(originalValue);
            originalValue = originalValue.trim();
            if (originalValue.indexOf("UserInfo") === 0){
                myValue = originalValue;
                myValue = myValue.replace("UserInfo=", "");
                return true;
            }
            return false;
        });
    } 

    if (myValue !== "") {
        try {
            return JSON.parse(myValue);
        } catch (error) {
            return JSON.parse('{"displayname": "", "apikey": "", "stage": ""}');
        }
    }
};

export var getParameterByName = function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};