import React from 'react';
import styled from "styled-components";

import { 
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton as MuiIconButton
} from '@material-ui/core';

import {Info as InfoIcon} from '@material-ui/icons';

const IconButton = styled(MuiIconButton)`
  padding: '0px',
`;

export default function PaymentForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Select Platform
      </Typography>
      <Grid container spacing={2} style={{marginTop: '15px', marginLeft: '15px' }}>
      <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="Amazon" value="yes" />}
            label="Amazon Marketplace"
          />
          <Tooltip title="About">
                <IconButton aria-label="about" color='secondary'>
                  <InfoIcon />
                </IconButton>
            </Tooltip>
          </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="Google" value="yes" />}
            label="Google Shopping"
          />
          <Tooltip title="About">
                <IconButton aria-label="about" color='secondary'>
                  <InfoIcon />
                </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="GoogleAds" value="yes" />}
            label="Google Ads"
          />
          <Tooltip title="About">
                <IconButton aria-label="about" color='secondary'>
                  <InfoIcon />
                </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="Facebook" value="yes" />}
            label="Facebook"
          />
          <Tooltip title="About">
                <IconButton aria-label="about" color='secondary'>
                  <InfoIcon />
                </IconButton>
            </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="instagram" value="yes" />}
            label="Instagram"
          />
          <Tooltip title="About">
                <IconButton aria-label="about" color='secondary'>
                  <InfoIcon />
                </IconButton>
            </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="LinkedIn" value="yes" />}
            label="LinkedIn"
          />
          <Tooltip title="About">
                <IconButton aria-label="about" color='secondary'>
                  <InfoIcon />
                </IconButton>
            </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
