import React from 'react';
import Helmet from 'react-helmet';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CssBaseline,
    Grid,
    Typography,
    Container
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/StarBorder';
import { makeStyles } from '@material-ui/core/styles';
import ProductSupport from '../home/ProductSupport';
import PricingDescription from './PricingDescription';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    root: {
        paddingBottom: theme.spacing(10)
    },
    descriptionroot: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light,
    },
    container: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(30),
        display: 'flex',
        position: 'relative',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    image: {
        height: 55,
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
    },
}));

const tiers = [
    {
        title: 'Basic',
        price: '15',
        description: [
            'Up to 10 listings',
            '1 user',
            'Management console',
            'Help center access',
            'Email support',
            'Low setup fee'
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        link: '/register'
    },
    {
        title: 'Pro',
        subheader: 'Most popular',
        price: '30',
        description: [
            'Up to 30 listings',
            '5 users',
            'Management console',
            'Help center access',
            'Priority email support',
            'Low setup fee'
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        link: '/register'
    },
    {
        title: 'Enterprise',
        price: '75',
        description: [
            'Unlimited listings',
            'Unlimited users',
            'Management console',
            'Help center access',
            'Phone & email support',
            'API and ERP Integration'
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
        link: '/contact'
    },
];

export default function Pricing() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Helmet title='Pricing | Everywhen Software' />
            <CssBaseline />
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Pricing
                </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                    We have a plan for everyone whether you're a business or an individual, 14-day trial no credit
                    card required.
                </Typography>
            </Container>
            <Container maxWidth="md" component="main" className={classes.root}>
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                                    className={classes.cardHeader}
                                />
                                <CardContent>
                                    <div className={classes.cardPricing}>
                                        <Typography component="h2" variant="h3" color="textPrimary">
                                            ${tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            /mo
                    </Typography>
                                    </div>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button fullWidth variant={tier.buttonVariant} color="primary" href={tier.link}>
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <PricingDescription />
            <ProductSupport />
        </React.Fragment>
    );
}