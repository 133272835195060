import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";
import AppAppBar from '../pages/components/AppAppBar';
import AppFooter from '../pages/components/AppFooter';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
`;

const Root = styled.div`
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
`;

function Layout({ children }) {
  return (
    <Root>
      <CssBaseline />
          <GlobalStyle />
          <AppAppBar />
          <AppContent>
            {children}
          </AppContent>
          <AppFooter />
    </Root>
  );
}

export default Layout;
