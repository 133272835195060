import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../../components/Typography';
import TextField from '../../components/TextField';
import Snackbar from '../../components/Snackbar';
import Button from '../../components/Button';
import computerImage from '../../img/macbook.jpg';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '80%',
    maxWidth: 600,
  },
});

function ProductCTA(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [emailAddress, setEmail] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    SendEmail();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const SendEmail = () => {
    if (emailAddress === "") {
      setError(true);
    }
    else {
        const requestOptions = {
            method: 'POST',
            //mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'POoZVoFTkgaEmb4dkSgp78lEcGeF72IRaKH7Deaz'
            },
            body: JSON.stringify({ 
                "emailaddress": emailAddress, 
                "subject": "Keep In Touch", 
                "message": "", 
                "firstname": "", 
                "lastname": "", 
            })
        };
        fetch('https://0wn4tevge0.execute-api.us-west-2.amazonaws.com/sandbox/sendcontactemail', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                  setOpen(true);
                  setError(false);
                  setEmail('');
                },
                (error) => {
                  setOpen(true);
                  setError(false);
                  setEmail('');
                }
            )
    }
};

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form onSubmit={handleSubmit} className={classes.cardContent}>
              <Typography variant="h2" component="h2" gutterBottom>
                Keep in touch
              </Typography>
              <Typography variant="h5">
                Be the first to know when an app is released.
              </Typography>
              <TextField 
                noBorder 
                className={classes.textField} 
                placeholder="Your email" 
                onChange={handleEmail} 
                error={error} />
              <Button type="submit" color="primary" variant="contained" className={classes.button}>
                Keep me updated
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src={computerImage}
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        onClose={handleClose}
        message="Thanks for siging up."
      />
    </Container>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);
