import React from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import logo from '../../img/marketlistlogo.png';
import { NavLink as RouterNavLink } from "react-router-dom";
import Container from '@material-ui/core/Container';

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const styles = (theme) => ({
    root: {
        color: theme.palette.common.white,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            height: '80vh',
            minHeight: 500,
            maxHeight: 1300,
        },
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    backdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.white,
        opacity: 0,
        zIndex: -1,
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -2,
    },
    arrowDown: {
        position: 'absolute',
        bottom: theme.spacing(4),
        [theme.breakpoints.up('lg')]: {
            bottom: theme.spacing(2),
        },
    },
    image: {
        width: '100px',
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            width: '50px',
            marginTop: theme.spacing(2),
        }
    },
    button: {
        minWidth: 200,
    },
    h2: {
        color: '#000000',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
        },
    },
    h4: {
        color: '#000000',
        fontSize: 32,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(4),
        },
        color: '#000000'
    },
    more: {
        marginTop: theme.spacing(2),
        color: '#000000'
    }
});

function ProductMain(props) {
    //const { backgroundClassName, classes } = props;
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <img src={logo} alt="Marketlist logo" className={classes.image} />
                <Typography color="inherit" align="center" variant="h2" marked="center" className={classes.h2}>
                    Marketlist
                </Typography>
                <Typography color="inherit" align="center" variant="h5" marked="center" className={classes.h4}>
                Streamline, Showcase, Succeed: Your Online Listings Elevated. 
                </Typography>
                <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
                    Be in the know.
                </Typography>
                <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    className={classes.button}
                    component={NavLink}
                    to="/register"
                >
                    {'Register'}
                </Button>
                <Typography variant="body2" color="inherit" className={classes.more}>
                    Discover the experience
                </Typography>
                <div className={classes.backdrop} />
                <img
                    className={classes.arrowDown}
                    src="/static/img/productHeroArrowDown.png"
                    height="20"
                    width="15"
                    alt="arrow down"
                />
            </Container>
        </section>
  );
}

ProductMain.propTypes = {
    classes: PropTypes.object.isRequired,
    //backgroundClassName: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProductMain);
