import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    MailOutline,
    Apps,
    HelpOutline,
    HomeOutlined,
    AccountCircle,
    Settings,
    LocalOffer,
    Dashboard as DashboardIcon,
    ShoppingCart,
    People,
    BarChart,
    Layers,
    Assignment,
    ExitToApp
} from '@material-ui/icons';

import HomeLayout from './layouts/HomeLayout';
import DashboardLayout from './layouts/DashboardLayout';
import Home from './pages/home/Home';
import Pricing from "./pages/pricing/Pricing";
import Blank from "./pages/Blank";
import SignUp from "./pages/auth/SignUp";
import SignIn from "./pages/auth/SignIn";
import Account from "./pages/auth/Account";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Blog from "./pages/blog/Blog";
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";
import Contacts from "./pages/contact/Contact";
import Product from "./pages/product/Index";
import Dashboard from "./pages/dashboard/Dashboard";
import Reports from "./pages/reports/Reports";
import Listings from "./pages/listings/Listings";
import NewListing from "./pages/listings/NewListing";
import Campaigns from "./pages/campaigns/Campaigns";

const navigationroutes = [
    {
        path: "/",
        name: "Home",
        icon: <HomeOutlined />,
        component: Home
    },
    {
        path: "/product",
        name: "Product",
        icon: <Apps />,
        component: Product
    },
    {
        path: "/pricing",
        name: "Pricing",
        icon: <LocalOffer />,
        component: Pricing
    },
    {
        path: "/register",
        name: "Register",
        icon: <MailOutline />,
        component: SignUp
    },
    // {
    //     path: "/news",
    //     name: "News",
    //     icon: <MoveToInbox />,
    //     component: Blog
    // }
];

const accountroutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: <DashboardIcon />,
        component: Dashboard
    },
    {
        path: "/myaccount",
        name: "My Account",
        icon: <Settings />,
        component: Account
    },
];

const contactroutes = [
    {
        path: "/about",
        name: "Our Story",
        icon: <AccountCircle />,
        component: Blank
    },
    {
        path: "/news",
        name: "News",
        icon: <HelpOutline />,
        component: Blog
    },
    {
        path: "/contact",
        name: "Contact Us",
        icon: <Settings />,
        component: Contacts
    }
];

const legalroutes = [
    {
        path: "/terms",
        name: "Terms",
        icon: <Settings />,
        component: Terms
    },
    {
        path: "/privacy",
        name: "Privacy",
        icon: <AccountCircle />,
        component: Privacy
    }
];

const authroutes = [
    {
        path: "/signin",
        name: "Sign In",
        icon: <AccountCircle />,
        component: SignIn
    },
    {
        path: "/signin?signout=TRUE",
        name: "Sign Out",
        icon: <ExitToApp />,
        component: SignIn
    }
];

const hiddenauthroutes = [
    {
        path: "/forgotpassword",
        name: "Forgot Password",
        icon: <ExitToApp />,
        component: ForgotPassword
    },
    {
        path: "/resetpassword",
        name: "Reset Password",
        icon: <ExitToApp />,
        component: ResetPassword
    }
];

const dashboardroutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: <DashboardIcon />,
        component: Dashboard
    },
    {
        path: "/listings",
        name: "Listings",
        icon: <ShoppingCart />,
        component: Listings
    },
    {
        path: "/campaigns",
        name: "Campaigns",
        icon: <People />,
        component: Campaigns
    },
    {
        path: "/reports",
        name: "Reports",
        icon: <BarChart />,
        component: Reports
    },
    {
        path: "/integrations",
        name: "Integrations",
        icon: <Layers />,
        component: Blank
    }
];

const recentroutes = [
    {
        path: "/current",
        name: "Current Month",
        icon: <Assignment />,
        component: Blank
    },
    {
        path: "/last",
        name: "LastQuarter",
        icon: <Assignment />,
        component: Blank
    },
    {
        path: "/year",
        name: "Year-End",
        icon: <Assignment />,
        component: Blank
    }
];

const listingroutes = [
    {
        path: "/newlisting",
        name: "New Listing",
        icon: <Assignment />,
        component: NewListing
    },
];

const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
              render={props => (
                  <Layout>
                      <Component {...props} />
                  </Layout>
            )}
        />
      ))
    ) : (
      // Route item without children
      <Route
            key={index}
            path={path}
            exact
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
      />
    )
  );

const Routes = () => (
  <Router>
    <Switch>
            {childRoutes(HomeLayout,navigationroutes)}
            {childRoutes(DashboardLayout, accountroutes)}
            {childRoutes(HomeLayout, authroutes)}
            {childRoutes(HomeLayout, hiddenauthroutes)}
            {childRoutes(HomeLayout, contactroutes)}
            {childRoutes(HomeLayout, legalroutes)}
            {childRoutes(DashboardLayout, dashboardroutes)}
            {childRoutes(DashboardLayout, recentroutes)}
            {childRoutes(DashboardLayout, listingroutes)}
            <Route
                render={() => (
                    <HomeLayout>
                        <Home />
                    </HomeLayout>
                )}
            />
    </Switch>
  </Router>
);

export const NavRoutes = navigationroutes;
export const AccountRoutes = accountroutes;
export const ContactRoutes = contactroutes;
export const LegalRoutes = legalroutes;
export const DashboardRoutes = dashboardroutes;
export const RecentRoutes = recentroutes;
export const AuthRoutes = authroutes;
export const HiddenAuthRoutes = hiddenauthroutes;
export const ListingRoutes = listingroutes;
export default Routes;
