import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Container,
    Typography,
    Link
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { NavLink as RouterNavLink } from "react-router-dom";

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  icon: {
    width: 50,
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;

  return (
      <Container className={classes.root} component="section">
          <Button
              className={classes.button}
              component={NavLink}
              to="/contact"
          >
                <Typography variant="h4" component="span">
                  Got any questions?
                </Typography>
          </Button>
      <Typography variant="subtitle1" className={classes.link}>
        We are here to help. Get in touch!
      </Typography>
      <Link to="/contact" variant="body2" component={NavLink}>
        <img src="/static/icons/mail.svg" className={classes.icon} alt="mail" />
       </Link>
    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
