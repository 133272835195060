import withRoot from '../../../modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Markdown from '../../components/Markdown';
import Typography from '../../components/Typography';
import terms from './terms.md';

function Terms() {
  return (
    <React.Fragment>
      <Helmet title='Terms | Everywhen Software' />
      <Container>
        <Box mt={7} mb={12}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Terms
          </Typography>
          <Markdown>{terms}</Markdown>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default withRoot(Terms);
