import React from 'react';
import Helmet from 'react-helmet';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Typography,
    Container
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import { getUserInfo } from '../../components/ScriptLibrary';
import Snackbar from '../../components/Snackbar';
import { email as validateEmail } from '../../form/validation';

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(41),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignUp() {
    const classes = useStyles();

    class SignUpForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                open: false,
                cMessage: "",
                emailAddress: "",
                password: "",
                confirmpassword:"",
                firstname:"",
                lastname:"",
                notifications: true,
                emailAddressError: false,
                passwordError: false,
                confirmpasswordError: false,
                firstnameError: false,
                lastnameError: false,
                login: getUserInfo().apikey ? true:false
            };
            this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
            this.handlePasswordChange = this.handlePasswordChange.bind(this);
            this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
            this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
            this.handleLastNameChange = this.handleLastNameChange.bind(this);
            this.handleNotificationsChange = this.handleNotificationsChange.bind(this);
        }
        
        handleEmailAddressChange = (e) => {
            this.setState({ emailAddress: e.target.value, emailAddressError: false });
        };
        
        handlePasswordChange = (e) => {
            this.setState({ password: e.target.value, passwordError: false });
        };

        handleConfirmPasswordChange = (e) => {
            this.setState({ confirmpassword: e.target.value, confirmpasswordError: false });
        };

        handleFirstNameChange = (e) => {
            this.setState({ firstname: e.target.value, firstnameError: false });
        };

        handleLastNameChange = (e) => {
            this.setState({ lastname: e.target.value, lastnameError: false });
        };

        handleNotificationsChange = (e) => {
            this.setState({ notifications:  !this.state.notifications});
        };

        handleClose = (e) => {
            this.setState({ cMessage: "", open: false });
        };
        
        Register = (e) => {
            e.preventDefault();

            const { emailAddress, password, confirmpassword, firstname, lastname, notifications } = this.state;

            if (firstname === "") {
                this.setState({ firstnameError: true });
            }
            else if (lastname === "") {
                this.setState({ lastnameError: true });
            }
            else if (emailAddress === "") {
                this.setState({ emailAddressError: true });
            }
            else if (password === "") {
                this.setState({ passwordError: true });
            }
            else if (confirmpassword === "") {
                this.setState({ confirmpasswordError: true });
            }
            else if (validateEmail(emailAddress)) {
                this.setState({ emailAddressError: true });
            }
            else if (password !== confirmpassword) {
                this.setState({ confirmpasswordError: true });
            }
            else {
                const requestOptions = {
                    method: 'POST',
                    //mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'POoZVoFTkgaEmb4dkSgp78lEcGeF72IRaKH7Deaz'
                    },
                    body: JSON.stringify({ 
                        "emailaddress": emailAddress, 
                        "password": password,
                        "confirmpassword": confirmpassword,
                        "firstname": firstname,
                        "lastname": lastname,
                        "notifications": notifications
                    })
                };
                
                fetch('https://0wn4tevge0.execute-api.us-west-2.amazonaws.com/sandbox/register', requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.err) {
                                this.setState({
                                    cMessage: result.err,
                                    open: true
                                });
                            }
                            else {
                                var cookieValue = getUserInfo(result.userinfo);
                                cookieValue = encodeURIComponent(JSON.stringify(cookieValue));
                                document.cookie = "UserInfo=" + cookieValue;
                                this.setState({
                                    open: false,
                                    emailAddress: "",
                                    password: "",
                                    confirmpassword: "",
                                    firstname: "",
                                    lastname: "",
                                    notifications: "",
                                    emailAddressError: false,
                                    passwordError: false,
                                    confirmpasswordError: false,
                                    firstnameError: false,
                                    lastnameError: false,
                                    cMessage: "Success!",
                                    login: true
                                 });
                            }
                        },
                        (error) => {
                            this.setState({
                                cMessage: "Unable to register user.",
                                open: true
                            });
                        }
                    )
            }
        };
        
        render = () => {
            const { open, cMessage, emailAddressError, passwordError, confirmpasswordError, firstnameError, lastnameError, login, notifications } = this.state;

            if (login) {
                return (
                    <Redirect to="/dashboard" component={NavLink} /> 
                );
            }
            else {
                return (
                    <Container component="main" maxWidth="xs">
                        <Helmet title='Sign Up | Everywhen Software' />
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>
                            <form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="fname"
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            error={firstnameError}
                                            onChange={this.handleFirstNameChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="lname"
                                            error={lastnameError}
                                            onChange={this.handleLastNameChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            type="email"
                                            error={emailAddressError}
                                            onChange={this.handleEmailAddressChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            error={passwordError}
                                            onChange={this.handlePasswordChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="confirmpassword"
                                            label="Confirm Password"
                                            type="password"
                                            id="confirmpassword"
                                            error={confirmpasswordError}
                                            onChange={this.handleConfirmPasswordChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox value="allowExtraEmails" color="primary" onChange={this.handleNotificationsChange} checked={notifications}/>}
                                            label="I want to receive marketing promotions and updates via email."
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={this.Register}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <Link to="/signin" variant="body2" component={NavLink}>
                                            Already have an account? Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <Snackbar
                            open={open}
                            onClose={this.handleClose}
                            message={cMessage}
                            />
                    </Container>
                );
            }
        }
    }
    return <SignUpForm />
}

export default SignUp;