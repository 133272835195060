import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    NavLink as RouterNavLink,
} from "react-router-dom";

import {
    ListItemText,
    ListItemIcon,
    ListItem,
    Divider,
    List,
    Button,
    Drawer,
} from '@material-ui/core';

import {
    Close,
} from '@material-ui/icons';

import { NavRoutes } from "../../Routes";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
        height: '100%',
        backgroundColor: 'black',
        color: 'white'
    },
    icon: {
        color: 'white'
    },
    divider: {
        backgroundColor: 'white'
    }
});

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

function NavLinks({ classes, name, to, icon, component }) {
    return (
        <ListItem
            button
            key={name}
            exact
            to={to}
            component={NavLink}
            activeClassName="active">
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>
    );
}

 function MenuDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
                <List>
                    {NavRoutes.map((route, index) => (
                        <NavLinks 
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            key={route.name}
                            classes={classes}
                            component={route.component}
                        />
                    ))}
            </List>
            <Divider className={classes.divider} />
            <List>
                <ListItem button key='Close'>
                    <ListItemIcon className={classes.icon}><Close /></ListItemIcon>
                    <ListItemText primary='Close' />
                </ListItem>
                </List>
            </div>
    );

    const anchor = 'top';

    return (
        <div>
                <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" color='white' stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></Button>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
                </React.Fragment>
        </div>
    );
}

export default MenuDrawer;
