import React from 'react';
import styled from "styled-components";

import {
  Typography,
  Grid,
  TextField,
  Tooltip,
  Button,
  IconButton,
  InputAdornment,
  MenuItem
} from '@material-ui/core';

import {AddCircle as AddIcon } from '@material-ui/icons';

const campaigns = [
  {
    value: 'Spring Event',
    label: 'Spring Event',
  },
  {
    value: 'Summer Event',
    label: 'Summer Event',
  },
  {
    value: 'Fall Event',
    label: 'Fall Event',
  },
  {
    value: 'Winter Event',
    label: 'Winter Event',
  },
];

const categories = [
  {
    value: 'Clothing and Accessories',
    label: 'Clothing and Accessories',
  },
  {
    value: 'Electronics',
    label: 'Electronics',
  },
  {
    value: 'Furniture',
    label: 'Furniture',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export default function PaymentForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Product Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField required id="name" label="Name" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          id="upc" 
          label="UPC" 
          fullWidth 
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <Tooltip title="Get UPC">
                <IconButton aria-label="get upc" color='secondary'>
                  <AddIcon />
                </IconButton>
              </Tooltip>
          </InputAdornment>,
          }}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField id="mpn" label="MPN" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          id="category" 
          label="Category" 
          fullWidth 
          variant="outlined"
          select>
            {categories.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          id="campaign" 
          label="Campaign" 
          fullWidth 
          variant="outlined"
          select>
            {campaigns.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField required id="description" label="Description" fullWidth multiline rows={15} variant="outlined"/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
