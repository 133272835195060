import React from "react";
import Helmet from 'react-helmet';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink as RouterNavLink } from "react-router-dom";

// core components
import GridContainer from "../../components/GridContainer.js";
import GridItem from "../../components/GridItem.js";
import Button from "../../components/Button.js";
import Parallax from "../../components/Parallax.js";

// Sections for this page
import ProductSection from "./ProductSection.js";
import { container, title } from "../../styles/material-kit-react.js";
import titleimage from "../../img/notebook.jpg";

const styles = {
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        marginBottom: '30px',
        ...container
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: "#FFFFFF",
        fontFamily: "'Roboto Condensed', sans-serif",
        textDecoration: "none"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    button: {
        marginBottom: '70px',
    },
};

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const classes = useStyles();
    //const { ...rest } = props;
    return (
        <div>
            <Helmet title='Product | Everywhen Software' />
            <Parallax filter image={titleimage}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Your Online Story Starts With Us.</h1>
                            <h4>
                                Navigating the world of online marketing can be frustrating. 
                                We're here to make it easy by providing an easy-to-use listing tool to help you quickly 
                                deploy your products on all the major platforms.  
                              </h4>
                            <br />
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                className={classes.button}
                                component={NavLink}
                                to="/register"
                            >
                                {'Get Started'}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <ProductSection />
                </div>
            </div>
        </div>
    );
}
