import React from "react";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

import Helmet from 'react-helmet';
import { Warning as WarningIcon } from '@material-ui/icons';

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Warning = styled(WarningIcon)`
    svg {
        width: 150px;
    }
`;

const useStyles = makeStyles({
    root: {
        padding: '0px 10px 0px 10px',
    },
    icon: {
        width: '50px'
    },
    title: {
        padding: '15px'
    }
});

function Blank() {
    const classes = useStyles();

  return (
    <React.Fragment>
     <Helmet title="Under Construction" />
          <Typography variant="h3" display="inline" className={classes.title}>
              <Warning className={classes.icon} /> Under Construction
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6} className={classes.root} alignItems='center'>
        <Grid item xs={12}>
            <Card mb={6}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Something good coming soon.
                    </Typography>
                </CardContent>
                </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Blank;
