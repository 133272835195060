import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button as MuiButton
} from '@material-ui/core';
import Typography from '../../components/Typography';
import { NavLink as RouterNavLink } from "react-router-dom";

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const styles = (theme) => ({
    root: {
        //display: 'flex',
        backgroundColor: theme.palette.secondary.light,
        overflow: 'auto',
    },
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        position: 'relative',
        //display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    title: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    image: {
        height: 55,
        padding: theme.spacing(4,4,4,4),
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
        opacity: 0.7,
    },
    button: {
        marginTop: theme.spacing(8),
    },
    table: {
        minWidth: '750px',
    },
    tableroot: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        overflowX: 'auto'
    },
    tableinactive: {
        [theme.breakpoints.up('sm')]: {
            width: '225px'
        }
	},
    tableactive: {
        // backgroundColor: 'rgba(0, 0, 0, 0.54)',
        // color: theme.palette.common.white,
        //width: '300px',
        [theme.breakpoints.up('sm')]: {
            width: '225px'
        }
    },
    tablebutton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    tablerowheader: {
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            width: '225px'
        }
    },
    tablebuttonrow: {
        backgroundColor: '#ffffff',
    },
    tableheader:{
        borderBottom: '0px',
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function PricingDescription(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Compare Pricing
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                        14-day trial no credit card required. Pay when you're ready to list your items.
                </Typography>
                <div>
                    <TableContainer component={Paper} className={classes.tableroot}>
                        <Table className={classes.table} aria-label="customized table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" className={classes.tableheader}></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableheader}>
                                        <Typography variant="h5" align="center" color="inherit">
                                            {'Basic'}
                                        </Typography>
                                        <Typography variant="body2" align="center" color="inherit">
                                            Ideal for Individuals starting a business.
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableheader}>
                                        <Typography variant="h5" align="center" color="inherit">
                                            {'Pro'}
                                        </Typography>
                                        <Typography variant="body2" align="center" color="inherit">
                                            Perfect for Small Businesses looking to grow.
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableheader}>
                                        <Typography variant="h5" align="center" color="inherit">
                                            {'Enterprise'}
                                        </Typography>
                                        <Typography variant="body2" align="center" color="inherit">
                                            Tailored for Large Companies who want to manage all of their listings in one easy solution.
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <StyledTableRow key='actions'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablebuttonrow}></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tablebuttonrow}>
                                        <MuiButton fullWidth size="small" variant='outlined' color="primary" to='/register' component={NavLink} className={classes.tablebutton}>
                                            {'Get Started'}
                                        </MuiButton></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tablebuttonrow}>
                                        <MuiButton fullWidth size="small" variant='contained' color="inherit" to='/register' component={NavLink} className={classes.tablebutton}>
                                            {'Get Started'}
                                        </MuiButton>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tablebuttonrow}>
                                        <MuiButton fullWidth size="small" variant='outlined' color="primary" to='/contact' component={NavLink} className={classes.tablebutton}>
                                            {'Contact Us'}
                                        </MuiButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='listings'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Listings</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}>10</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}>30</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}>Unlimited</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='users'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Users</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}>1</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}>5</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}>Unlimited</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='management'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Management Console</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='analytics'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Analytics</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='helpcenter'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Help Center Access</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='support'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Support Level</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}>Email Only</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}>Priority Email</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}>Phone and Email</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='import'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Import Tool</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='upc'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Purchase UPC Codes*</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='automatic'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Automatic Updates*</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='erp'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>ERP Integration*</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key='reporting'>
                                    <StyledTableCell component="th" scope="row" className={classes.tablerowheader}>Advanced Reporting</StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></StyledTableCell>
                                    <StyledTableCell align="center" className={classes.tableinactive}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body2" align="flex-start" color="textSecondary" component="p">
                        * A valid Amazon Seller and/or Google Merchant account is required to list items. Valid social media business accounts are required for social media ad campaigns.
                        <br />
                        * For Basic accounts, a one-time listing fee of $24.99 USD will be applied per item. 
                        <br />
                        * For Pro accounts, a one-time listing fee of $19.99 USD will be applied per item.
                        <br />
                        * Minimum 3-month subscription required for all Basic and Pro accounts. The full amount for the first 3 months will be billed when the first item is listed.
                        <br />
                        * Minimum 1 year subscription required for all Enterprise accounts. The full 1 year amount will be billed when the first item is listed.
                        <br />
                        * Additional charges may apply for advanced integration. Please contact our team for more information.
                </Typography>
                </div>
            </Container>
        </section>
    );
}

PricingDescription.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PricingDescription);
