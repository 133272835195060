import React from 'react';

import Routes from './modules/Routes';
import withRoot from './modules/withRoot';
import ReactGA from 'react-ga';

const trackingId = "UA-166077333-1";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function Index() {
    return (
        <React.Fragment>
            <Routes />
        </React.Fragment>
    );
}

export default withRoot(Index);
