import React from "react";
import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Bar } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import appTheme from "../../theme";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 10px;
  }
`;

const ChartWrapper = styled.div`
  height: 253px;
  width: 100%;
`;

const styles = (theme) => ({
  root: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
          height: '80vh',
          minHeight: 500,
          maxHeight: 1300,
      },
  },
  container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(14),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  },
  primary: {

  },
  secondary: {

  }
});

const BarChart = () => {
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    datasets: [
      {
        label: "Mobile",
        backgroundColor: appTheme.palette.primary.light,
        borderColor: appTheme.palette.primary.light,
        hoverBackgroundColor: appTheme.palette.primary.light,
        hoverBorderColor: appTheme.palette.primary.light,
        data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
        barPercentage: 0.625,
        categoryPercentage: 0.5
      },
      {
        label: "Desktop",
        backgroundColor: appTheme.palette.secondary.main,
        borderColor: appTheme.palette.secondary.main,
        hoverBackgroundColor: appTheme.palette.secondary.main,
        hoverBorderColor: appTheme.palette.secondary.main,
        data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
        barPercentage: 0.625,
        categoryPercentage: 0.5
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false
          },
          stacked: false,
          ticks: {
            stepSize: 20
          }
        }
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent"
          }
        }
      ]
    }
  };

  return (
    <Card mb={3}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertical />
          </IconButton>
        }
        title="Mobile / Desktop"
      />
      <CardContent>
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(BarChart);
