import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { NavLink as RouterNavLink } from "react-router-dom";
import { ShoppingCartOutlined, DashboardOutlined, InsertChartOutlined } from "@material-ui/icons";


const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
    image: {
        height: 75,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    },
    button: {
        marginTop: theme.spacing(8),
    },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/static/img/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
              />
        <Grid container justify="center">
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
              How it works
        </Typography>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <ShoppingCartOutlined className={classes.image} />
              <Typography variant="h6" className={classes.title}>
                We make it easy 
              </Typography>
              <Typography variant="h5">
                {'Our listing wizard will guide you through the entire process step-by-step. We provide everything you need for a successful listing.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <DashboardOutlined className={classes.image} />
              <Typography variant="h6" className={classes.title}>
                Everything in one place
              </Typography>
              <Typography variant="h5">
                {'Integrate with Amazon Marketplace and Google Shopping all in one easy-to-use dashboard. Make one-time product updates or push changes to all platforms with a single click.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
          <div className={classes.item}>
              <InsertChartOutlined className={classes.image}/>
              <Typography variant="h6" className={classes.title}>
                Powerful Analytics
              </Typography>
              <Typography variant="h5">
                {'See all the information you need to boost your selling power. Run reports to help optimize every listing so you can sell more with less work.'}
              </Typography>
            </div>
          </Grid>
              </Grid>
              <Grid container justify="center">
              <div>
              <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  className={classes.button}
                  component={NavLink}
                  to="/register"
              >
                  {'Get Started'}
              </Button>
             </div>
         </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
