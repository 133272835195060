import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    ListItemText,
    ListItemIcon,
    ListItem,
    Divider,
    List,
    Button,
    Drawer 
} from '@material-ui/core';

import {
    Close
} from '@material-ui/icons';

import { NavLink as RouterNavLink } from "react-router-dom";

import { AccountRoutes, AuthRoutes } from "../../Routes";
import { getUserInfo } from "../../components/ScriptLibrary";

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles({
    list: {
        width: 225,
    },
    fullList: {
        width: 'auto',
        color: 'black'
    },
    icon: {
        color: 'black'
    },
    divider: {
        backgroundColor: 'black'
    }
});

function NavLinks({ classes, name, to, icon }) {
    const login = getUserInfo().apikey ? true : false;

    if (login && name === "Sign In") {
        return null;
    }
    else if (!login && name === "Sign Out") {
        return null;
    }
    else {
        return (
            <ListItem
                button
                key={name}
                component={NavLink}
                exact
                to={to}
                activeClassName="active">
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        );
    }
}

function MenuDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
            <div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <List>
                    {AccountRoutes.map((route, index) => (
                        <NavLinks
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            key={route.name}
                            classes={classes}
                        />
                    ))}
                    {AuthRoutes.map((route, index) => (
                        <NavLinks
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            key={route.name}
                            classes={classes}
                        />
                    ))}
                </List>
                <Divider className={classes.divider} />
                <ListItem button key='Close'>
                    <ListItemIcon className={classes.icon}><Close /></ListItemIcon>
                    <ListItemText primary='Close' />
                </ListItem>
            </div>
    );

    const anchor = 'top';

    return (
        <div>
            <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}><svg xmlns=" http://www.w3.org/2000/svg" color='white' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg></Button>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default MenuDrawer;
