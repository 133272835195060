import React from "react";
import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';

import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography as MuiTypography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const styles = (theme) => ({
  root: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
          height: '80vh',
          minHeight: 500,
          maxHeight: 1300,
      },
  },
  container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(14),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  }
});

const Typography = styled(MuiTypography)(spacing);

function Stats({ title, amount, chip, percentageText, percentagecolor }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" mb={6}>
          {title}
        </Typography>
        <Typography variant="h3" mb={6}>
          <Box fontWeight="fontWeightRegular">{amount}</Box>
        </Typography>
        <Typography
          variant="subtitle1"
          mb={6}
          percentagecolor={percentagecolor}
        >
          <span>{percentageText}</span> Since last week
        </Typography>
        <Chip label={chip} />
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(Stats);
