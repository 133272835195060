import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { 
  CssBaseline, 
  Divider as MuiDivider,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Link,
  Typography 
} from '@material-ui/core';

import Helmet from 'react-helmet';
import PlatformForm from './PlatformForm';
import ProductForm from './ProductForm';
import DetailsForm from './DetailsForm';
import PaymentForm from './PaymentForm';
import Review from './Review';

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://everywhensoftware.com/">
              Everywhen Software
    </Link>{' '}
          {new Date().getFullYear()}
          
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  iconactive: {
    color: theme.palette.secondary.main + ' !important'
  },
  iconcompleted: {
    color: theme.palette.secondary.main + ' !important'
  }
}));

const steps = ['Platform', 'Product', 'Details', 'Payment', 'Review'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PlatformForm />;
    case 1:
      return <ProductForm />;
    case 2:
      return <DetailsForm />;
    case 3:
      return <PaymentForm />;
    case 4:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSave = () => {
    //setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Helmet title="New Listing | Everywhen Software" />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            New Listing
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel 
                  StepIconProps={{
                    classes: {
                      completed: classes.iconcompleted,
                      active: classes.iconactive,
                    }
                  }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order confirmation, and will
                  send you an update when your order has shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <React.Fragment>
                    <Button onClick={handleSave} className={classes.button}>
                      Save
                    </Button>
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                    </React.Fragment>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'List Item' : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
