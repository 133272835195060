import withRoot from '../../withRoot';
// --- Post bootstrap -----
import React from 'react';
import Helmet from 'react-helmet';
//import ProductCategories from '../views/ProductCategories';
import ProductSupport from './ProductSupport';
import ProductMain from './ProductMain';
import ProductValues from './ProductValues';
//import ProductHowItWorks from '../views/ProductHowItWorks';
import ProductCTA from './ProductCTA';

function Index() {
  return (
    <React.Fragment>
          <Helmet title='Home | Marketlist Software' />
          <ProductMain />
          <ProductValues />
          <ProductCTA />
          <ProductSupport />
    </React.Fragment>
  );
}

export default withRoot(Index);
