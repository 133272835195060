import React from 'react';
import Helmet from 'react-helmet';
import {
    Link,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Container
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterNavLink, Redirect } from "react-router-dom";
import Snackbar from '../../components/Snackbar';
import { getUserInfo, getParameterByName } from '../../components/ScriptLibrary';

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(50),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignIn() {
    const classes = useStyles();

    class SignInForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                open: false,
                cMessage: "",
                emailAddress: "",
                password: "",
                emailAddressError: false,
                passwordError: false,
                login: getUserInfo().apikey ? true:false
            };
            this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
            this.handlePasswordChange = this.handlePasswordChange.bind(this);
        }
        
        handleEmailAddressChange = (e) => {
            this.setState({ emailAddress: e.target.value, emailAddressError: false });
        };
        
        handlePasswordChange = (e) => {
            this.setState({ password: e.target.value, passwordError: false });
        };

        handleClose = (e) => {
            this.setState({ cMessage: "", open: false });
        };
        
        LogIn = (e) => {
            e.preventDefault();

            const { emailAddress, password } = this.state;

           if (emailAddress === "") {
                this.setState({ emailAddressError: true });
            }
            else if (password === "") {
                this.setState({ passwordError: true });
            }
            else {
                const requestOptions = {
                    method: 'POST',
                    //mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'POoZVoFTkgaEmb4dkSgp78lEcGeF72IRaKH7Deaz'
                    },
                    body: JSON.stringify({ 
                        "emailaddress": emailAddress, 
                        "password": password,
                    })
                };

                fetch('https://0wn4tevge0.execute-api.us-west-2.amazonaws.com/sandbox/login', requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.err != null) {
                                this.setState({
                                    cMessage: "Invalid login attempt.",
                                    open: true
                                });
                            }
                            else {
                                var cookieValue = getUserInfo(result.userinfo);
                                cookieValue = encodeURIComponent(JSON.stringify(cookieValue));
                                document.cookie = "UserInfo=" + cookieValue;
                                this.setState({
                                    open: false,
                                    emailAddress: "",
                                    password: "",
                                    emailAddressError: false,
                                    passwordError: false,
                                    cMessage: "Success!",
                                    login: true
                                 });
                            }
                        },
                        (error) => {
                            this.setState({
                                cMessage: "Unable to Login.",
                                open: true
                            });
                        }
                    )
            }
        };
        
        render = () => {
            const {open, cMessage, emailAddress, emailAddressError, passwordError, login} = this.state;
            const signout = getParameterByName("signout", window.location.href);

            if (signout === 'TRUE') {
                document.cookie = "UserInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
                return (
                    <Redirect to="/signin" component={NavLink} /> 
                 );
            }
            else if (login) {
                return (
                        <Redirect to="/dashboard" component={NavLink} /> 
                );
            }
            else {
                return (
                    <React.Fragment>
                        <Helmet title='Sign In | Everywhen Software' />
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Sign in
                                </Typography>
                                <form className={classes.form} noValidate>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={emailAddress}
                                        autoFocus
                                        onChange={this.handleEmailAddressChange}
                                        error={emailAddressError}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={this.handlePasswordChange}
                                        error={passwordError}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label="Remember me"
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={this.LogIn}
                                    >
                                        Sign In
                                    </Button>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link to="/forgotpassword" variant="body2" component={NavLink}>
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link to="/register" variant="body2" component={NavLink}>
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                            <Snackbar
                            open={open}
                            onClose={this.handleClose}
                            message={cMessage}
                            />
                        </Container>
                    </React.Fragment>
                );
            }
        }
    }
    return <SignInForm />
}

export default SignIn;