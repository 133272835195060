import React from "react";
import PropTypes from 'prop-types';
import { 
     Grid,

 } from "@material-ui/core";
 import { withStyles } from '@material-ui/core/styles';
 import Typography from '../../components/Typography';
 import Actions from "./Actions";

 const styles = (theme) => ({
    title: {
        color: theme.palette.primary.main
    }
  });

  function PageHeader(props) {
    const { classes, pagetitle, displayactions } = props;

    return (
    <React.Fragment>
        <Grid justify="space-between" container spacing={6}>
            <Grid item>
            <Typography variant="h4" display="inline" className={classes.title}>
                {pagetitle}
            </Typography>
            </Grid>
            <Grid item>
                {displayactions ? (<Actions />): (null)}
            </Grid>
        </Grid>
    </React.Fragment>
    );
}

PageHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    pagetitle: PropTypes.string.isRequired,
    displayactions: PropTypes.bool
};

export default withStyles(styles)(PageHeader);
