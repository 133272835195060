import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Timelapse, VerifiedUser, Assessment, Autorenew, AddToQueue, Whatshot } from "@material-ui/icons";
import { NavLink as RouterNavLink } from "react-router-dom";

// core components
import GridContainer from "../../components/GridContainer.js";
import GridItem from "../../components/GridItem.js";
import InfoArea from "../../components/InfoArea.js";
import Button from "../../components/Button.js";
import { title } from "../../styles/material-kit-react.js";

const styles = {
    section: {
        padding: "70px 0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        fontFamily: "'Roboto Condensed', sans-serif",
        fontSize: '36px'
    },
    description: {
        color: "#999"
    },
    button: {
        marginTop: '70px',
        marginBottom: '70px'
    }
};

const useStyles = makeStyles(styles);

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
                      Our online marketing tool will walk you through listing your items step-by-step. By the end
                      of the wizard, we guarantee you will have all the information needed to complete a listing on
                      Amazon or Google.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Get Everything You Need Here"
              description="We provide access to everything you need for a successful listing including UPC codes, shipping labels, and all required forms. Not sure what you need? Don't worry, our software will guide you through the entire process."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Save Time"
              description="Manage and update all of your listings in one easy-to-use dashboard. Make single updates or do a mass update of all listings with a few clicks."
              icon={Timelapse}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Powerful Analytics"
              description="Get in depth insight into how your listings are doing. Create reports, alerts, and budgets to maximize your selling power."
              icon={Assessment}
              iconColor="danger"
              vertical
            />
          </GridItem>
              </GridContainer>
              <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                      <InfoArea
                          title="Integrate With Your Existing ERP"
                          description="Import product information from your existing ERP via a spreadsheet to avoid re-keying."
                          icon={AddToQueue}
                          iconColor="warning"
                          vertical
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                      <InfoArea
                          title="Automatically Update Listings"
                          description="For our Enterprise customers, we offer automatic listing updates when the product is updated in your ERP."
                          icon={Autorenew}
                          iconColor="success"
                          vertical
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                      <InfoArea
                          title="Get Social"
                          description="Need to reach more potential buyers? Use our social media ad tools to create campaigns on Facebook, Instagram, and LinkedIn."
                          icon={Whatshot}
                          iconColor="info"
                          vertical
                      />
                  </GridItem>
              </GridContainer>
              <GridContainer justify='center'>
                  <GridItem xs={12} >
                      <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          className={classes.button}
                          component={NavLink}
                          to="/pricing"
                      >
                          {'Compare Pricing'}
                      </Button>
                  </GridItem>
              </GridContainer>
      </div>
    </div>
  );
}
