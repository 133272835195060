import React from 'react';
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
// import  post1  from './blog-post-1.md';
// import post2 from './blog-post-2.md';
// import post3 from './blog-post-3.md';
import iphones from '../../img/iphones.jpg';
import macbook from '../../img/macbook.jpg';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    },
    root: {
        marginBottom: theme.spacing(10),
    },
}));

const sections = [
  { title: 'Technology', url: '#' },
  { title: 'Design', url: '#' },
  { title: 'Culture', url: '#' },
  { title: 'Business', url: '#' },
  { title: 'Opinion', url: '#' },
  { title: 'Science', url: '#' },
  { title: 'Health', url: '#' },
  { title: 'Style', url: '#' },
  { title: 'Travel', url: '#' },
];

const mainFeaturedPost = {
  title: 'New and Popular',
  description:
    "Check out our latest releases and read about how we're improving our most popular apps.",
    image: iphones,
  imgText: 'main image description',
  linkText: 'Continue reading…',
};

const featuredPosts = [
  {
    title: 'Upcoming Products',
    date: 'Nov 12',
    description: "Learn about what we're working on and our product roadmap.",
        image: macbook,
    imageText: 'Image Text',
  },
  {
    title: 'Our Favorites',
    date: 'Nov 11',
    description: "Oldies but goodies. Our most popular products of all time in one place.",
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
];

function getPosts() {
    //var posts = [post1, post2, post3];
    return [];
}

function getArchives() {
    var archives = [];

    var d = new Date();
    var month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    var curYear = d.getFullYear();

    for (var i = 0; i < 10; i++) {
        var curMonth = d.getMonth() - i; 
        if (curMonth < 0) {
            if (curMonth === -1) {
                curYear = curYear - 1;
            }
            curMonth = curMonth + 12;
        }

        var monthName = month[curMonth];
        var archive = {
            title: monthName + ' ' + curYear,
            url: '#'
        };
        archives.push(archive);
    }
   
    return archives;
}

const sidebar = {
  title: 'About',
  description:
    'You have products to sell, we make it easy.',
  archives: getArchives(),
};

export default function Blog() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet title='News | Everywhen Software' />
      <CssBaseline />
          <Container maxWidth="lg" className={classes.root} >
        <Header title="News" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} className={classes.mainGrid}>
            <Main title="Recent" posts={getPosts()} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
            />
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}
